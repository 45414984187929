<template>
  <div class="other">
    <OtherHeaderTop
      v-if="!isMobile"
      :is-show-profile-page="isShowProfilePage"
      :is-show-blacklist-page="isSupport"
      :main-page-params="getMainPage"
      @goToMainPageUser="goToMainPageUser"
      @goToQuotas="goToQuotas"
      @goToProfile="goToProfile"
      @goToBlacklist="goToBlacklist"
    />
    <OtherHeaderBottom
      :is-show-profile-page="isShowProfilePage"
      :main-page-params="getMainPage"
      @goToMainPageUser="goToMainPageUser"
      @goToProfile="goToProfile"
    />
    <router-view />
  </div>
</template>

<script>
import { usersRoles } from '@/constants/roles'
import OtherHeaderBottom from '@/components/layouts/other/other-header/other-header-bottom/OtherHeaderBottom'
import OtherHeaderTop from '@/components/layouts/other/other-header/other-header-top/OtherHeaderTop'
import libraryMixin from '@/mixins/libraryMixin'

export default {
  name: 'Other',
  components: { OtherHeaderTop, OtherHeaderBottom },
  mixins: [libraryMixin],
  computed: {
    activeIndexMenu() {
      return this.getParentPageRoute
    },
    isShowProfilePage() {
      return this.$route.name !== 'UserProfile'
    },
    getMainPage() {
      return usersRoles.filter(item => item.value === this.currentRole)[0]
    },
  },
  methods: {
    goToProfile() {
      this.$router.push({ name: 'UserProfile' })
    },
    goToBlacklist() {
      this.$router.push({ name: 'Blacklist' })
    },
    goToMainPageUser() {
      this.$router.push({
        name: this.isExporter ? 'ExporterSuppliers' : 'Manager',
      })
    },
    goToQuotas() {
      this.$router.push({
        name: this.isExporter ? 'ExporterQuotas' : 'HomeSupport',
      })
    },
  },
}
</script>

<style lang="sass">
.other
  .profile
    padding: 20px 0
</style>
