import {
  FETCH_CULTURES,
  FETCH_CULTURES_PAGE,
  FETCH_EXPORTERS_ALL,
  FETCH_EXPORTERS_PAGE,
} from '@/views/control/store/actions'
import { FETCH_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import {
  FETCH_TERMINALS,
  GET_TERMINAL_CURRENT_ID,
  GET_UPDATE_ACTION,
} from '@/store/actions'
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getTerminalCurrentId: GET_TERMINAL_CURRENT_ID,
      updateAction: GET_UPDATE_ACTION,
    }),
  },
  watch: {
    getTerminalCurrentId() {
      this.fetchLibs()
    },
  },
  mounted() {
    this.fetchLibs(true)
  },
  methods: {
    ...mapActions({
      getTerminals: FETCH_TERMINALS,
      getCulture: FETCH_CULTURES,
      getCulturePage: FETCH_CULTURES_PAGE,
      getSuppliers: FETCH_SUPPLIERS_ALL,
      getExportersAll: FETCH_EXPORTERS_ALL,
      getExportersPage: FETCH_EXPORTERS_PAGE,
      GET_UPDATE_ACTION,
    }),
    fetchLibs(fetchTerminals = false) {
      if (fetchTerminals) {
        this.getTerminals()
      }
      this.getCulture()
      this.getSuppliers()
      this.getExportersAll()

      if (this.$route.name.includes('Control')) {
        this.getExportersPage()
        this.getCulturePage()
      }
      // this.updateAction()
    },
  },
}
