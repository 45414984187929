var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "other" },
    [
      !_vm.isMobile
        ? _c("OtherHeaderTop", {
            attrs: {
              "is-show-profile-page": _vm.isShowProfilePage,
              "is-show-blacklist-page": _vm.isSupport,
              "main-page-params": _vm.getMainPage,
            },
            on: {
              goToMainPageUser: _vm.goToMainPageUser,
              goToQuotas: _vm.goToQuotas,
              goToProfile: _vm.goToProfile,
              goToBlacklist: _vm.goToBlacklist,
            },
          })
        : _vm._e(),
      _c("OtherHeaderBottom", {
        attrs: {
          "is-show-profile-page": _vm.isShowProfilePage,
          "main-page-params": _vm.getMainPage,
        },
        on: {
          goToMainPageUser: _vm.goToMainPageUser,
          goToProfile: _vm.goToProfile,
        },
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }